<template>
  <div class="w-100">
    <div class="my-1 modal-branch">
      <b-button
        class="btn-select-branch w-100 w-sm-unset text-nowrap"
        @click.prevent="show($event)"
      >
        {{ text }}
        <font-awesome-icon icon="chevron-right" class="ft-14 ml-2" />
      </b-button>
      <span class="ml-2 d-none d-md-inline-flex p-black">
        (Selected Branch {{ countBranch }} List)
      </span>
    </div>
    <div>
      <b-modal
        v-model="showModal"
        ref="ModalSelectBranch"
        centered
        @hidden="hide"
        id="modal-branch"
      >
        <template #modal-header>Branch List</template>
        <b-form-group>
          <template #label>
            <b-form-checkbox
              v-model="selectAllBranch"
              @change="toggleAllBranch()"
              :disabled="isBranchDisable"
              class="my-2"
            >
              All Branches
            </b-form-checkbox>
          </template>
          <b-tabs content-class="mt-3" v-if="!isCounting" ref="b_tabs" fill>
            <b-tab v-for="(item, index) in list" :key="index">
              <template v-slot:title>
                <div>
                  <span>{{ item.name }}</span>
                  <span> ({{ item.selected_count }}) </span>
                </div>
              </template>
              <b-form-group>
                <!-- <pre>{{ item }}</pre> -->
                <template>
                  <b-form-checkbox
                    :ref="`all_${item.name}`"
                    v-model="allSelected"
                    :key="item.name"
                    :value="item.name"
                    @change="toggleAll(item.name, index)"
                    class="checkDisable"
                    :tabindex="index"
                    :disabled="isBranchDisable"
                  >
                    {{ item.name }} All Branches
                  </b-form-checkbox>
                </template>

                <b-form-checkbox-group
                  v-model="selected"
                  :disabled="item.is_disabled"
                  text-field="name"
                  value-field="id"
                  class="mt-3"
                >
                  <b-form-checkbox
                    v-for="branch of item.branchList"
                    :value="branch.id"
                    :key="branch.id"
                    :disabled="branch.is_disabled"
                    >{{ branch.plant_id }} {{ branch.name }}</b-form-checkbox
                  >
                </b-form-checkbox-group>
              </b-form-group>
            </b-tab>
          </b-tabs>
        </b-form-group>
        <template #modal-footer>
          <b-row>
            <b-col class="text-left">
              <b-button @click.prevent="showModal = false" class="border-btn"
                >Cancel</b-button
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-button class="submit-btn" @click.prevent="acceptSelectBranch()"
                >Select {{ selected.length }} Branch</b-button
              >
            </b-col>
          </b-row>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    BranchSelectList: {
      required: false,
    },
    list: {
      required: true,
      type: Array,
    },
    text: {
      required: true,
      type: String,
    },
    form: {
      required: true,
    },
    id: {
      required: true,
      type: String,
    },
    valid: {
      required: false,
      type: Number,
    },
    isBranchDisable: {
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      selected: [],
      BranchSelected: [],
      allSelected: [],
      selectAllBranch: false,
      allBranch: [],
      branch: "",
      countBranch: 0,
      allBranchCheck: 0,
      allSelectBranchCheck: 0,
      branchOld: [],
      lockSelected: [],
      isCounting: false,
      branchDelete: [],
      deleteBranch: [],
      tabIndex: 0,
      tabSelected: 0,
    };
  },

  watch: {
    "form.branch_id"(val) {
      return (this.countBranch = val.length);
    },
  },
  mounted() {},
  methods: {
    show() {
      let branch_id = [...this.form.branch_id];
      if (!this.form.delete_branch_id) this.form.delete_branch_id = [];
      branch_id = branch_id.filter(
        (id) => !this.form.delete_branch_id.includes(id)
      );
      if (this.form.branch_id && this.form.add_branch_id) {
        this.selected = [...branch_id, ...this.form.add_branch_id];
      } else {
        this.selected = [...branch_id];
      }

      this.showModal = true;

      this.isSelectAll();
    },
    hide() {
      // this.selected = [];

      // this.BranchSelected = [...this.BranchSelectList];
      this.showModal = false;
    },
    toggleAll(branchGroup, tabIndex) {
      const focusGroup = this.list.filter((el) => {
        return el.name == branchGroup;
      })[0];
      const checkBoxEl = this.$refs[`all_${branchGroup}`][0];
      const b_tabs = this.$refs.b_tabs;
      if (checkBoxEl) {
        if (checkBoxEl.isChecked) {
          for (const branchToAdd of focusGroup.branchList) {
            if (!this.selected.includes(branchToAdd.id)) {
              this.selected.push(branchToAdd.id);
            }
            if (b_tabs && b_tabs.currentTab == tabIndex) {
              if (
                branchToAdd.is_check &&
                this.deleteBranch.includes(branchToAdd.id)
              ) {
                this.deleteBranch.splice(
                  this.deleteBranch.indexOf(branchToAdd.id),
                  1
                );
              }
            }
          }
        } else if (b_tabs) {
          for (const branchToRm of focusGroup.branchList) {
            if (this.selected.includes(branchToRm.id)) {
              if (b_tabs.currentTab == tabIndex) {
                if (this.valid === 0) {
                  this.selected.splice(this.selected.indexOf(branchToRm.id), 1);
                  if (
                    branchToRm.is_check &&
                    !this.deleteBranch.includes(branchToRm.id)
                  ) {
                    this.deleteBranch.push(branchToRm.id);
                  }
                } else {
                  this.selected.splice(this.selected.indexOf(branchToRm.id), 1);
                  if (!this.deleteBranch.includes(branchToRm.id)) {
                    this.deleteBranch.push(branchToRm.id);
                  }
                }
              }
            }
          }
        }
      }
      this.isSelectAll();
    },
    toggleAllBranch() {
      if (this.selectAllBranch) {
        for (const participating of this.list) {
          this.allSelected.push(participating.name);
          for (const branch of participating.branchList) {
            if (!this.selected.includes(branch.id)) {
              this.selected.push(branch.id);
            }
          }
        }
      } else {
        this.selected = [];
        this.allSelected = [];
      }
      this.isSelectAll();
    },
    isSelectAll() {
      let isCheckAllBranchCount = 0;
      for (const participating of this.list) {
        let branchSelectedCount = 0;
        for (const branch of participating.branchList) {
          if (!participating.selected_count) participating.selected_count = 0;
          if (this.selected.includes(branch.id)) {
            branchSelectedCount = branchSelectedCount + 1;
          }
        }
        participating.selected_count = branchSelectedCount;

        if (branchSelectedCount == participating.branchList.length) {
          if (!this.allSelected.includes(participating.name)) {
            this.allSelected.push(participating.name);
          }
          isCheckAllBranchCount = isCheckAllBranchCount + 1;
        } else if (this.allSelected.includes(participating.name)) {
          this.allSelected.splice(
            this.allSelected.indexOf(participating.name),
            1
          );
        }
      }
      if (isCheckAllBranchCount == this.list.length && this.list.length !== 0) {
        this.selectAllBranch = true;
      } else this.selectAllBranch = false;
      this.isCounting = false;
    },
    onChange(id) {
      this.isCounting = true;
      for (const branchToDeleteId of this.branchDelete) {
        if (
          !this.selected.includes(branchToDeleteId) &&
          !this.deleteBranch.includes(branchToDeleteId)
        ) {
          this.deleteBranch.push(branchToDeleteId);
        } else if (
          this.selected.includes(branchToDeleteId) &&
          this.deleteBranch.includes(branchToDeleteId)
        ) {
          this.deleteBranch.splice(
            this.deleteBranch.indexOf(branchToDeleteId),
            1
          );
        }
      }
      this.isSelectAll();
    },
    acceptSelectBranch() {
      this.countBranch = this.selected.length;
      this.$emit("selectBranch", this.selected);
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.ft-weight {
  font-weight: 600;
}

::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
::v-deep .modal-footer {
  justify-content: space-between;
}
::v-deep a {
  color: #707070 !important;
}

::v-deep .modal-dialog {
  @media (min-width: 576px) {
    max-width: 800px;
  }
}
.btn-cancel {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.pointer {
  cursor: pointer;
}
.confirm-btn:disabled {
  background-color: var(--font-disable-color) !important;
  border-color: var(--font-disable-color);
}
::v-deep .custom-control-inline {
  margin-right: unset;
  width: 50%;
}
</style>
